import React from 'react';
import Faq from "react-faq-component";
import "./faq.css"
import Arrow from "../../assets/Arrow-down.svg"

const data ={
  rows:
  [
    {
        title: 'Czym wyróżnia się oferta?',
        content: 'Zapewne każdy, kto planuje wesele, zastanawia się, jakiego DJ wybrać. Jeśli chcecie, by Wasze wesele trwało do białego rana, parkiet był pełen ludzi, a muzyka łączyła pokolenia, to dobrze trafiliście. Pamiętajcie, że muzyka łączy i powoduje, że Wasz ślub może wznieść się na inny wymiar. Chcecie się przekonać, jak stworzyć wesele marzeń za pomocą odpowiedniej oprawy muzycznej? Czekam na Was!.'
    },
    {
        title: 'Jakie elementy zawiera podstawowa oferta?',
        content: 'W pakiecie podstawowym sam osobiście prowadzę was przez całe wesele, zarówno muzycznie jak i konferansjersko. W cenie jest oświetlenie oraz nagłośnienie + wybrany wygląd konsoli. Na życzenie organizuję wszelkiego rodzaju zabawy integracyjne. Polecam także zapoznać się z ofertą dodatkową, ponieważ można bardzo fajnie umilić czas gościom dzięki artystom.'
    },
    {
        title: 'Skład, w którym występujemy',
        content: 'Sami decydujecie o tym jak tak naprawdę wygląd cały skład. W ofercie podstawowej jest DJ / Konferansjer do, którego możecie dobrać artystów: skrzypce, saksofon, wokale czy dodatkowego DJa. Artyście wykonują od 30% do 40% utworów, dzięki temu nie znudzą się szybko'
    },
    {
        title: 'Jaki zakres godzin obejmuje nasza usługa?',
        content: 'Nie macie limitu godzin, gramy maksymalnie do godziny 4:00 - oczywiście wszystko jest do ustalenia'
    },
    {
        title: 'Czy nasza usługa obejmuje również oprawę konferansjerską?',
        content: 'Oczywiście, to jeden z ważniejszych punktów. Prowadzę Was od początku do końca wesela. Staram się, aby goście nie czuli się zagubieni w tak ważnym dniu!'
    },
    {
        title: 'Wesele międzynarodowe',
        content: 'Prowadzę wesela międzynarodowe w języku angielskim, hiszpańskim, niemieckim. Odpowiednio zgrywamy się z preferencjami klientów zagranicznych zarówno od strony konferansjerskiej jak i muzycznej. Dlatego jeśli Twoi goście są z zagranicy, nie musisz obawiać się o barierę językową. Pozwól, aby każdy na Twoim ślubie czuł się jak w domu!'
    },
      {
          title: 'Jak długo pracujemy w branży ślubnej?',
          content: 'Ponad 12 lat zajmuję się prowadzeniem wszelkiego rodzaju imprez okolicznościowych, bardzo duży nacisk na wesela przypada na okres około 8 lat. Przez ten czas szukałem swojego miejsca, które znalazłem i czuję się w nim szczęśliwy. A wiecie, co jest najważniejsze? Aby czuć się wspaniale w swojej pracy! Moja praca zapewnia mi ogromną satysfakcję i dokładam wszelkich starań, by każdy z moich klientów czuł się jak najlepiej!'
      },
      {
          title: 'Jaki repertuar proponujemy?',
          content: 'Pod tym względem jestem bardzo elastyczny. Przekrój muzyki, jaką mogę Wam zaprezentować jest nieograniczony. Dzięki współpracy z Wami ustalamy, co możemy zagrać, czy czego nie chcielibyście usłyszeć. Chętnie gram wszelkiego rodzaju muzykę taneczną, szalone lata 60., 70., 80. i 90. nie są dla mnie żadną zagadką. Z chęcią sięgam także po klasyki muzyki Rock, Rock n Roll czy nutkę muzyki latino. To, co? Kto z Was chce przenieść się w szalone rytmy i wskoczyć na parkiet?'
      },
      {
          title: 'Czy oferujemy efekty świetlne w trakcie prowadzenia imprezy?',
          content: 'Tak, w zależności od wybranej konsoli światło dostosowane jest do klimatu wesela.'
      },
      {
          title: 'Czy możecie zaproponować własną listę utworów?',
          content: 'Oczywiście, że mogę! Jednocześnie jestem na tyle elastyczny, że zawsze dogadujemy sprawę muzyki przed weselem - jest to wasz dzień więc warto byście wiedzieli co będzie się działo na parkiecie. Możecie zaproponować własną listę piosenek, do której będę się odnosił w czasie wesela.'
      },
      {
          title: 'Czy goście weselni mogą zamawiać piosenki i dedykacje?',
          content: 'Bez problemu - z chęcią przyjmuję sugestie gości, o ile są one na poziomie. Utwory staram się zagrać jeszcze w tym samym bloku muzycznym. Jestem nie tylko do Waszej, ale również do dyspozycji gości podczas uroczystości weselnej!'
      },
  ]


}
const styles = {
  bgColor: 'transparent',
  titleTextColor: "#fff",
  rowTitleColor: "#fff",
  rowContentColor: '#fff',
  arrowColor: "#fff",
};

const config = {
  // animate: true,
   arrowIcon: <Arrow />,
  // tabFocus: true
};

export default function FaqInfo() {
 // const classes = useStyles();
 return (
  <div>
      <Faq
          data={data}
          styles={styles}
          config={config}
      />
  </div>
);
}
